import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Fade, Flex, Grid, IconButton, Text, Spinner } from "@chakra-ui/react";
import { DragHandleIcon, HamburgerIcon, ViewOffIcon } from "@chakra-ui/icons";

import PatientItem from "./PatientItem";
import { useWindowSize } from "../utils/util";

import { sortAndFilterByDate } from "../utils/util";
import { sortByDate } from "../utils/util";

const styles = {
  content: {
    padding: ".5rem",
  },
  noResults: {
    textAlign: "center",
    padding: ".5rem",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),  0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
};
const PER_PAGE = 9;

function sortAndFilterPatients(patients) {
  const preliminaryPatients = patients.filter(
    (patient) => patient.studyStatus === "Waiting To Be Read"
  );
  const inProgressPatients = patients.filter(
    (patient) => patient.studyStatus === "In progress"
  );
  const signedPatients = patients.filter(
    (patient) => patient.studyStatus === "Signed"
  );
  const archivedPatients = patients.filter(
    (patient) => patient.studyStatus === "Archived"
  );
  const amendedPatients = patients.filter(
    (patient) => patient.studyStatus === "Amended"
  );

  return [
    ...preliminaryPatients,
    ...inProgressPatients,
    ...signedPatients,
    ...archivedPatients,
    ...amendedPatients,
  ];
}

const PatientList = ({ patients = [], user, loading }) => {
  const windowSize = useWindowSize();
  const [page, setPage] = useState(0);
  const [displayStyle, setDisplayStyle] = useState("cards");

  const displayedPatients = sortByDate(patients).slice(
    0,
    PER_PAGE + page * PER_PAGE
  );

  //  console.log("display", displayedPatients);

  //  const toggleDisplayStyle = (style) => {
  //    setDisplayStyle(style);
  //  };

  return (
    <div>
      {/*      <div
        style={{
          width: "90vw",
          margin: "1rem auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <p
          style={{
            display: "flex",
            background: "rgba(255, 255, 255, .6)",
            borderRadius: "10px",
            padding: ".25rem",
            boxShadow: "2px 2px 1px 0px rgba(0,0,0,0.2)",
          }}
        >
          <IconButton
            m=".25rem"
            aria-label="Switch to list view"
            onClick={() => toggleDisplayStyle("list")}
            colorScheme={displayStyle === "list" ? "blue" : "gray"}
            icon={<HamburgerIcon />}
          />
          <IconButton
            m=".25rem"
            aria-label="Switch to grid view"
            onClick={() => toggleDisplayStyle("cards")}
            colorScheme={displayStyle === "cards" ? "blue" : "gray"}
            icon={<DragHandleIcon />}
          />
        </p>
      </div>
*/}
      {loading ? (
        <Flex
          direction="column"
          align="center"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10rem 0 15rem",
            alignItems: "center",
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      ) : (
        <div style={styles.content}>
          {displayedPatients.length > 0 && (
            <Fade in={displayedPatients.length > 0}>
              <InfiniteScroll
                loadMore={setPage}
                hasMore={displayedPatients.length < patients.length}
              >
                <Grid
                  w="90vw"
                  m="0 auto"
                  templateColumns={
                    displayStyle === "cards"
                      ? windowSize.width > 1450
                        ? "repeat(4, 1fr)"
                        : windowSize.width < 500
                        ? "1fr"
                        : "repeat(3, 1fr)"
                      : "1fr"
                  }
                >
                  {displayedPatients.map((patient) => (
                    <PatientItem
                      key={patient.SeriesInstanceUID}
                      patient={patient}
                      displayStyle={displayStyle}
                      user={user}
                    />
                  ))}{" "}
                </Grid>
              </InfiniteScroll>
            </Fade>
          )}

          {/* for use case Google email confirmed but not adminConfirmed */}
          {!user.attributes["custom:adminConfirmed"] &&
          user.attributes["custom:clinicName"] !== "demo" ? (
            <Text m="1rem" fontSize="lg">
              Welcome to Augmented Reporting and congratulations on creating
              your account!
              <br />
              <br />
              Confirmation will be emailed to {user.attributes.email} after
              access to your studies has been completed by our Support Team.
            </Text>
          ) : (
            !patients.length && (
              <Flex style={styles.noResults} direction="column" align="center">
                {patients.length === 0 ? null : (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                )}
                <Text m="1rem" fontSize="lg">
                  {patients.length === 0 ? "No results" : "Loading results"}
                </Text>
                {/*<ViewOffIcon w={24} h={24} p="1rem" />

            <Text color="red" m="1rem 0">
              If you think this is a mistake, please email us:
              support@futurepacs.com.
            </Text>
            */}
              </Flex>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default PatientList;
