import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Box, Text } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

import store from "../store";
import { loadPatients } from "../actions";
import { filteredPatientsSelector } from "../selectors";
import { fetchPatients } from "../services/patient.service";

import PatientList from "./PatientList";
import PatientFilter from "./PatientFilter";
import ScrollTopButton from "./ScrollTopButton";

const PatientBrowser = ({
  user,
  filteredPatients,
  filterOptions: { activeFilters, nameFilter },
}) => {
  const [statusFilteredPatients, setStatusFilteredPatients] = useState([]);

  const [loading, setLoading] = useState(false); // To track loading state
  const [isBackgroundDataLoading, setIsBackgroundDataLoading] = useState(false);

  useEffect(() => {
    const updateReduxStore = async (isInitialLoad, page, limit) => {
      // The first loader should be shown only on the initial load
      setLoading(isInitialLoad && limit === 12); // Set loading to true before the API call
      // update redux store on page load, this keeps the data fresh

      if (!isInitialLoad || limit !== 12) {
        setIsBackgroundDataLoading(true);
      }

      const patients = await fetchPatients(isInitialLoad, page, limit);
      // console.log(
      //   "fetching patients in updateReduxStore",
      //   isInitialLoad,
      //   patients
      // );

      // const patients = await fetchPatients();
      store.dispatch(loadPatients(patients));
      setLoading(false); // Set loading to false after the API call

      if (!isInitialLoad) {
        setIsBackgroundDataLoading(false);
      }
    };

    updateReduxStore(true, 1, 12);

    const timer1 = setTimeout(() => {
      updateReduxStore(true, 1, 50);
    }, 500);

    const timer2 = setTimeout(() => {
      updateReduxStore(false);
    }, 1500);

    // cleanup function to remove the timeouts when the component is unmounted
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    if (nameFilter) {
      setStatusFilteredPatients(filteredPatients);
    } else {
      if (activeFilters.includes("signedOnly")) {
        const signedPatients = filteredPatients.filter(
          (patient) => patient.studyStatus === "Signed"
        );
        setStatusFilteredPatients(signedPatients);
      } else {
        if (activeFilters.includes("archivedStudies")) {
          const archivedPatients = filteredPatients.filter(
            (patient) => patient.studyStatus === "Archived"
          );
          setStatusFilteredPatients(archivedPatients);
        } else {
          if (
            user.attributes["custom:role"] === "external" ||
            user.attributes["custom:clinicName"] === "demo" ||
            user.attributes["custom:role"] === "patient"
          ) {
            const externalPatients = filteredPatients.filter(
              (patient) => patient.studyStatus !== "Archived"
            );
            setStatusFilteredPatients(externalPatients);
          } else {
            const unsignedPatients = filteredPatients.filter(
              (patient) =>
                patient.studyStatus !== "Signed" &&
                patient.studyStatus !== "Archived"
            );
            setStatusFilteredPatients(unsignedPatients);
          }
        }
      }
    }
  }, [activeFilters, filteredPatients, nameFilter]);

  console.log("statusFiltered", statusFilteredPatients);

  // hide test reports from the Learner who has already opened the test report
  const learnerFilteredPatients =
    user.attributes["custom:role"] === "Educator"
      ? statusFilteredPatients
      : statusFilteredPatients.filter(
          (patient) =>
            !(
              patient.studyStatus === "Waiting To Be Read" &&
              patient.audit.find(
                (elem) =>
                  elem.accessType === "view" &&
                  elem.user === user.attributes.email &&
                  elem.role === "Learner"
              )
            )
        );

  console.log("learnerFiltered", learnerFilteredPatients);

  return (
    <div>
      <PatientFilter filteredPatients={learnerFilteredPatients} />
      <PatientList
        patients={learnerFilteredPatients}
        user={user}
        loading={loading}
      />
      <ScrollTopButton />
      {isBackgroundDataLoading ? <BackgroundDataLoaderIndicator /> : null}
    </div>
  );
};

function BackgroundDataLoaderIndicator() {
  return (
    <Box
      marginTop="16px"
      marginBottom="16px"
      // textAlign="center"
      // pointerEvents="none"
      position="fixed"
      bottom={0}
      left={0}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      columnGap={4}
      backgroundColor="rgba(250, 240, 137, 0.9)"
      padding="8px"
    >
      <Text display="flex" flexDirection="row" alignItems="center">
        Data might still be loading in the background
      </Text>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  filteredPatients: filteredPatientsSelector(state),
  filterOptions: state.filterOptions,
});

export default connect(mapStateToProps)(PatientBrowser);
