// import { findLocalItems } from "../utils/util";
import Cookies from "js-cookie";
import {
  getCurrentSession,
  getCurrentAuthenticatedUser,
} from "../services/auth.service";
import { hasMatchingWord } from "../utils/util";

export async function fetchPatients(isInitialLoad, page, limit) {
  const user = await getCurrentAuthenticatedUser();
  const formapiurl = `${user?.attributes["custom:gateway"]}/formapi/api`;

  let FORM_API_URL = formapiurl + "/series";

  if (isInitialLoad) {
    FORM_API_URL += `?page=${page}&limit=${limit}`;
  }

  // ensure fresh tokens
  const session = await getCurrentSession();

  // if no user logged in
  if (session == null) {
    var strClientAccessToken = Cookies.get("ClientAccessToken");
  } else strClientAccessToken = session.accessToken.jwtToken;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    accesstoken: strClientAccessToken,
  };

  // for testing  const newFormAPIPatients = [];

  const newFormAPIPatients = await fetch(FORM_API_URL, {
    method: "GET",
    headers: {
      ...headers,
    },
    /*    credentials: 'include' // include cookies in the request */
  })
    .then(handleErrors)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  if (
    newFormAPIPatients instanceof Error /* || oldAPIPatients instanceof Error */
  ) {
    return [];
  }

  const patients = isInitialLoad
    ? [...newFormAPIPatients?.items]
    : [...newFormAPIPatients];

  const clinic = user?.attributes?.["custom:clinicName"];
  const role = user?.attributes?.["custom:role"];
  const signature = user?.attributes?.["custom:signatureName"];

  // all patients with an immutable value (e.g. PatientID) that matches a user custom attribute (e.g. signatureName)

  const authPatients = patients.filter(
    (patient) =>
      user?.attributes["custom:adminConfirmed"] &&
      (role === "Sonographer" ||
        (role === "Cardiologist" &&
          !(
            patient.studyStatus === "In progress" &&
            !signature?.includes(patient.PhysiciansOfRecord?.slice(-4))
          )) ||
        (role === "Learner" &&
          (patient.LearnerID === undefined ||
            !(patient.LearnerID !== user?.attributes["custom:learnerID"]))) ||
        role === "Educator" ||
        (role === "Patient" &&
          patient.PatientID &&
          signature === patient.PatientID))
  );

  console.log("authPatients", authPatients);

  // Source: https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
  // Sort based on SeriesDate then by SeriesTime
  const sortedPatients = authPatients.sort((a, b) =>
    a.SeriesDate > b.SeriesDate
      ? 1
      : a.SeriesDate === b.SeriesDate
      ? a.SeriesTime > b.SeriesTime
        ? 1
        : -1
      : -1
  );
  sortedPatients.reverse();

  return Promise.resolve(
    sortedPatients.map((patient) => {
      // calculate the slug
      patient.slug = patient.SeriesInstanceUID;
      patient.studyIUID = patient.StudyInstanceUID;

      return patient;
    })
  );
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
