/**
 * Filter Config
 *
 * Stores the main filter config object and exports a bunch of
 * utility functions to help work with it. A 'filter' can exist in
 * two distinct forms;
 *
 * * An intention from the user (e.g. in their options)
 * * A filter that is ready to be applied
 *
 * In this config, the key is the "rule" and filters can have the
 * following properties;
 *
 * label: The human-readable label to display for this filter
 * dialogComponent: (optional) Dialog associated with filter
 * buildFilter: Function for constructing a filter from user options
 *
 * the `buildFilter` function of each filter config entry will be
 * passed two arguments;
 *
 * - the user filter options
 * - the rest of the state
 *
 * it should  return an object representing the filter rule to apply.
 * At the very least this needs to specify a "rule" property - this
 * will be used to determine which function to  call in `filterRules`
 * in order to actually apply the filter.
 *
 */
import { keys, get } from "lodash";

/* import {
  GlassFilterDialog,
  CategoryFilterDialog,
  DiagnosisFilterDialog,
} from "./components/Filters";
*/

/**
 * Main filter config object
 */
const filterConfig = {
  abnormalsOnly: {
    label: "Abnormals",
    buildFilter: () => ({
      rule: "mustBeTrue",
      property: "abnTotal",
    }),
  },
  favouritesOnly: {
    label: "Favourites",
    buildFilter: (_, { favourites }) => ({
      rule: "isFavourite",
      favourites,
    }),
  },
  waitingToBeReadOnly: {
    label: "Waiting To Be Read",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "Waiting To Be Read",
    }),
  },
  inProgressOnly: {
    label: "In progress",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "In progress",
    }),
  },
  signedOnly: {
    label: "Signed",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "Signed",
    }),
  },
  todaysStudies: {
    label: "Today's Studies",
    buildFilter: () => ({
      rule: "todaysStudies",
      property: "SeriesDate",
    }),
  },
  freeFormStudies: {
    label: "Free Form",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "free",
    }),
  },
  physiologicalStressStudies: {
    label: "Physiological Stress",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "stress",
    }),
  },
  dobutamineStressStudies: {
    label: "Dobutamine Stress",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "dobutamine",
    }),
  },
  pediatricStudies: {
    label: "Pediatric",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "ProtocolName",
      value: "pediatric",
    }),
  },
  archivedStudies: {
    label: "Archived",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "Archived",
    }),
  },
  amendedStudies: {
    label: "Amended",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "Amended",
    }),
  },
};

if (
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "sso" ||
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "CNEAT" ||
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "demo"
) {
  filterConfig["goldReportStudies"] = {
    label: "Test Reports",
    buildFilter: () => ({
      rule: "mustBeTrue",
      property: "isGoldReport",
    }),
  };
  filterConfig["testsInProgress"] = {
    label: "Tests In Progress",
    buildFilter: () => ({
      rule: "mustHaveStudyStatus",
      property: "studyStatus",
      value: "Test In Progress",
    }),
  };
}

if (process.env.REACT_APP_DEPLOY_ENVIRONMENT === "mkmed") {
  filterConfig["drAlRiyamiStudies"] = {
    label: "Dr. Al Riyami's studies",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "PhysiciansOfRecord",
      value: "al riyami",
    }),
  };

  filterConfig["drZuberiStudies"] = {
    label: "Dr. Zuberi's studies",
    buildFilter: () => ({
      rule: "mustIncludeValue",
      property: "PhysiciansOfRecord",
      value: "zuberi",
    }),
  };
}

function getFilterConfig(filterRule) {
  return filterConfig[filterRule && filterRule.toString()];
}

// Fishes out the label for a filter rule (if there is one)
export function labelFor(filterRule) {
  return get(getFilterConfig(filterRule), "label") || filterRule;
}

// Fishes out the custom dialog for a filter rule (if there is one)
export function dialogFor(filterRule) {
  return get(getFilterConfig(filterRule), "dialogComponent");
}

// Returns true if this filter rule has a custom dialog
export function hasDialog(filterRule) {
  return !!dialogFor(filterRule);
}

// HoF for building the actual filters that will be applied from
// user options.
export function buildFilter(filterRule) {
  return get(getFilterConfig(filterRule), "buildFilter");
}

// Returns a list of the rules
export function getRules() {
  return keys(filterConfig);
}
