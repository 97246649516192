import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

// import { Amplify } from "aws-amplify";
import { ColorModeScript } from "@chakra-ui/color-mode";

import App from "./App";

import store from "./store";
// import awsconfig from "./aws-exports";
import { loadDatabase } from "./utils/db.utils";
import * as serviceWorker from "./serviceWorker";

// Amplify.configure(awsconfig);

async function start() {
  // create the Redux store
  await loadDatabase(store);
  render(
    <Router>
      <Provider store={store}>
        <ColorModeScript initialColorMode="light" />
        <App />
      </Provider>
    </Router>,
    document.getElementById("root")
  );
}

start();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
