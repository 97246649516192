import { useFormikContext } from "formik";
import { Menu, Item, Separator, Submenu } from "react-contexify";
import "react-contexify/ReactContexify.css";

export default function CNEATContextMenus(menuid) {
  const { setFieldValue, values } = useFormikContext();

  //  console.log("menuid", menuid.menuid);

  function HandleItemClick({ event, triggerEvent, data }) {
    let key = triggerEvent.srcElement.id;
    let testConclusion = document.getElementById("testConclusion").id;

    if (event.detail === 1) {
      if (values[key]) {
        setFieldValue(key, values[key] + ". " + data);
      } else {
        setFieldValue(key, data);
      }
    }

    if (event.detail === 2) {
      if (values[testConclusion]) {
        setFieldValue(testConclusion, values[testConclusion] + ". " + data);
      } else {
        setFieldValue(testConclusion, data);
      }
    }
  }

  switch (menuid.menuid) {
    case "lvSizeAndFunction":
      return (
        <Menu id="lvSizeAndFunction">
          <Item closeOnClick={false} onClick={HandleItemClick} data="Normal LV">
            Normal LV
          </Item>
          <Submenu label="LV Size">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV size"
            >
              Normal
            </Item>
            <Submenu label="Dilation">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV is small"
              >
                Small
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV is mildly dilated"
              >
                Mildly dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV is moderately dilated"
              >
                Moderately dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV is severely dilated"
              >
                Severely dilated
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="LV Pathologies">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LVOT Gradient"
            >
              LVOT Gradient
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LV Tumor"
            >
              LV Tumor
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hypertrophic cardiomyopathy"
            >
              Hypertrophic cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LV Mass or thrombus"
            >
              LV Mass or thrombus
            </Item>
          </Submenu>
          <Submenu label="LV Diastolic function">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV diastolic function"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Grade 1 (Abnormal relax.)"
            >
              Grade 1 (Abnormal relax.)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Grade 2 (Pseudonormal)"
            >
              Grade 2 (Pseudonormal)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Grade 3 (Restrictive)"
            >
              Grade 3 (Restrictive)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Indeterminate LV diastolic function"
            >
              Indeterminate
            </Item>
          </Submenu>
          <Submenu label="LV Ejection fraction">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV diastolic function"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hyperdynamic (>70%)"
            >
              Hyperdynamic ({">"}70%)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal (52-70%)"
            >
              Normal (52-70%)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mildly reduced (41-< 52%)"
            >
              Mildly reduced (41-{"<"} 52%)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderately reduced (30%- <40%)"
            >
              Moderately reduced (30%- {"<"}40%)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Severely reduced (=<30%)"
            >
              Severely reduced (={"<"}30%)
            </Item>
          </Submenu>
          <Submenu label="Regional WMA">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="No regional WMA"
            >
              No
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal septal motion"
            >
              Abnormal septal motion
            </Item>
            <Submenu label="Yes">
              <Submenu label="Septal">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Normal septal RWMA"
                >
                  1 - Normal
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Hypokinesis septal RWMA"
                >
                  2 - Hypokinesis
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Akinesis septal RWMA"
                >
                  3 - Akinesis
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dyskinesis septal RWMA"
                >
                  4 - Dyskinesis
                </Item>
              </Submenu>
              <Submenu label="Lateral">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Normal lateral RWMA"
                >
                  1 - Normal
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Hypokinesis lateral RWMA"
                >
                  2 - Hypokinesis
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Akinesis lateral RWMA"
                >
                  3 - Akinesis
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dyskinesis lateral RWMA"
                >
                  4 - Dyskinesis
                </Item>
              </Submenu>
              <Submenu label="Anterior">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal anterior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal anterior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal anterior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal anterior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid anterior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid anterior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid anterior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid anterior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Apical">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal apical anterior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis apical anterior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis apical anterior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis apical anterior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Anterolateral">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal anterolateral RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal anterolateral RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal anterolateral RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal anterolateral RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid anterolateral RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid anterolateral RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid anterolateral RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid anterolateral RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Anteroseptal">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal anteroseptal RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal anteroseptal RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal anteroseptal RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal anteroseptal RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid anteroseptal RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid anteroseptal RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid anteroseptal RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid anteroseptal RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Inferoseptal">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal inferoseptal RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal inferoseptal RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal inferoseptal RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal inferoseptal RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid inferoseptal RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid inferoseptal RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid inferoseptal RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid inferoseptal RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Inferolateral">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal inferolateral RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal inferolateral RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal inferolateral RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal inferolateral RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid inferolateral RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid inferolateral RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid inferolateral RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid inferolateral RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Inferior">
                <Submenu label="Basal">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal basal inferior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis basal inferior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis basal inferior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis basal inferior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Mid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal mid inferior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis mid inferior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis mid inferior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis mid inferior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
                <Submenu label="Apical">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Normal apical inferior RWMA"
                  >
                    1 - Normal
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Hypokinesis apical inferior RWMA"
                  >
                    2 - Hypokinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Akinesis apical inferior RWMA"
                  >
                    3 - Akinesis
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Dyskinesis apical inferior RWMA"
                  >
                    4 - Dyskinesis
                  </Item>
                </Submenu>
              </Submenu>
            </Submenu>
          </Submenu>
          <Submenu label="LV Wall Thickness">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV Wall Thickness"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Concentric remodeling"
            >
              Concentric remodeling
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Concentric hypertrophy"
            >
              Concentric hypertrophy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Eccentric hypertrophy"
            >
              Eccentric hypertrophy
            </Item>
          </Submenu>
        </Menu>
      );

    case "aortaAndAv":
      return (
        <Menu id="aortaAndAv">
          <Submenu label="Aortic Valve">
            <Submenu label="Structure">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal structure aortic valve"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Sclerosis structure aortic valve"
              >
                Sclerosis
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Tricuspid structure aortic valve"
              >
                Tricuspid
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Bicuspid structure aortic valve"
              >
                Bicuspid
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Sinus of valsalva rupture"
              >
                Sinus of Valsalva Rupture
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Rheumatic structure aortic valve"
              >
                Rheumatic
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Lambl's excrescences"
              >
                Lambl's excrescences
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Subaortic stenosis"
              >
                Subaortic stenosis
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Fibroelastoma"
              >
                Fibroelastoma
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Abscess structure aortic valve"
              >
                Abscess
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Vegetation structure aortic valve"
              >
                Vegetation
              </Item>
              <Submenu label="Prosthetic AV">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mechanical prosethetic aortic valve"
                >
                  Mechanical Aortic
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Bioprosethetic aortic valve"
                >
                  Biosprothetic Aortic
                </Item>
                <Submenu label="Regurgitation">
                  <Submenu label="Paravalvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild paravalvular regurgitation prosthetic aortic valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate paravalvular regurgitation prosthetic aortic valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe paravalvular regurgitation prosthetic aortic valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                  <Submenu label="Pathologic Valvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild pathologic valvular regurgitation prosthetic aortic valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate pathologic valvular regurgitation prosthetic aortic valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe pathologic valvular regurgitation prosthetic aortic valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Elevated Gradient">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Obstruction from Thrombus/Pannus elevated gradient prosthetic aortic valve"
                  >
                    Obstruction from Thrombus/Pannus
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Patients Prosthesis Mismatch elevated gradient aortic valve"
                  >
                    Patients Prosthesis Mismatch
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="High Flow elevated gradient prosthetic aortic valve"
                  >
                    High Flow
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Structural valve degeneration elevated gradient prosthetic aortic valve"
                  >
                    Structural valve degeneration
                  </Item>
                </Submenu>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dehiscence prosthetic aortic valve"
                >
                  Dehiscence
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Endocarditis prosthetic aortic valve"
                >
                  Endocarditis
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Regurgitation">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Trivial/mild regurgitation aortic valve"
              >
                Trivial/mild
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderate regurgitation aortic valve"
              >
                Moderate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severe regurgitation aortic valve"
              >
                Severe
              </Item>
            </Submenu>
            <Submenu label="Stenosis">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Trivial/mild stenosis aortic valve"
              >
                Trivial/mild
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderate stenosis aortic valve"
              >
                Moderate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severe stenosis aortic valve"
              >
                Severe
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="Pulmonary Valve">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pulmonary Valve normal"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal pulmonary valve structure"
            >
              Abnormal pulmonary valve structure
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="≥ Moderate pulmonary regurgitation"
            >
              ≥ Moderate pulmonary regurgitation
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="≥ Moderate pulmonary stenosis"
            >
              ≥ Moderate pulmonary stenosis
            </Item>
            <Submenu label="RAP">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="~ 3 mmHg RAP"
              >
                ~ 3 mmHg
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="~8-10 mmHg RAP"
              >
                ~8-10 mmHg
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Elevated ~15 mmHg RAP"
              >
                Elevated ~15 mmHg
              </Item>
            </Submenu>
            <Submenu label="RVSP">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal RVSP"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly increased RVSP"
              >
                Mildly increased
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately increased RVSP"
              >
                Moderately increased
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely increased RVSP"
              >
                Severely increased
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Insufficient TR to estimate RVSP"
              >
                Insufficient TR to estimate RVSP
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings consistent with acute pulmonary embolism"
            >
              Findings consistent with acute pulmonary embolism
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings consistent with pulmonary hypertension"
            >
              Findings consistent with pulmonary hypertension
            </Item>
          </Submenu>
        </Menu>
      );

    case "rvPaPv":
      return (
        <Menu id="rvPaPv">
          <Submenu label="RV Size">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal RV size"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Enlarged RV size"
            >
              Enlarged
            </Item>
          </Submenu>
          <Submenu label="RV Function">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal RV function"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mild RV hypokinesis"
            >
              Mild hypokinesis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate RV hypokinesis"
            >
              Moderate hypokinesis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Severe RV hypokinesis"
            >
              Severe hypokinesis
            </Item>
          </Submenu>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="RV Pressure Overload"
          >
            RV Pressure Overload
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="RV Volume Overload"
          >
            RV Volume Overload
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="RV pacemaker lead"
          >
            RV pacemaker lead
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="RA pacemaker lead"
          >
            RA pacemaker lead
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Catheter in RA"
          >
            Catheter in RA
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="RV Mass / Thrombus"
          >
            RV Mass / Thrombus
          </Item>
        </Menu>
      );

    case "mvAndTv":
      return (
        <Menu id="mvAndTv">
          <Submenu label="Mitral Valve">
            <Submenu label="Structure">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal structure mitral valve"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Rheumatic structure mitral valve"
              >
                Rheumatic
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="MAC structure mitral valve"
              >
                MAC
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Thickened structure mitral valve"
              >
                Thickened
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Prolapse structure mitral valve"
              >
                Prolapse
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Calcified structure mitral valve"
              >
                Calcified
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Cleft structure mitral valve"
              >
                Cleft
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Systolic anterior motion structure mitral valve"
              >
                Systolic Anterior Motion
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Flail structure mitral valve"
              >
                Flail
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Vegetation structure mitral valve"
              >
                Vegetation
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Myxomatous structure mitral valve"
              >
                Myxomatous
              </Item>
              <Submenu label="Tethered">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Anterior tethered structure mitral valve"
                >
                  Anterior
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Posterior tethered structure mitral valve"
                >
                  Posterior
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Both tethered structure mitral valve"
                >
                  Both
                </Item>
              </Submenu>
              <Submenu label="Prosthetic MV">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mitral Valve repair prosthetic"
                >
                  Mitral Valve repair
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mechanical Mitral Valve prosthetic"
                >
                  Mechanical Mitral Valve
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Bioprosthetic Mitral Valve prosthetic"
                >
                  Bioprosthetic Mitral Valve
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Normal function (including washing jets/normal regurgitation) Mitral Valve prosthetic"
                >
                  Normal function (including washing jets/normal regurgitation)
                </Item>
                <Submenu label="Regurgitation">
                  <Submenu label="Paravalvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild paravalvular regurgitation prosthetic mitral valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate paravalvular regurgitation prosthetic mitral valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe paravalvular regurgitation prosthetic mitral valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                  <Submenu label="Pathologic Valvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild pathologic valvular regurgitation prosthetic mitral valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate pathologic valvular regurgitation prosthetic mitral valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe pathologic valvular regurgitation prosthetic mitral valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Elevated Gradient">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Obstruction from Thrombus/Pannus elevated gradient prosthetic mitral valve"
                  >
                    Obstruction from Thrombus/Pannus
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Patients Prosthesis Mismatch elevated gradient mitral valve"
                  >
                    Patients Prosthesis Mismatch
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="High Flow elevated gradient prosthetic mitral valve"
                  >
                    High Flow
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Structural valve degeneration elevated gradient prosthetic mitral valve"
                  >
                    Structural valve degeneration
                  </Item>
                </Submenu>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dehiscence mitral valve prosthetic"
                >
                  Dehiscence
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Endocarditis mitral valve prosthetic"
                >
                  Endocarditis
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
          <Submenu label="Tricuspid Valve">
            <Submenu label="Structure">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal structure tricuspid valve"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Fails to coapt"
              >
                Fails to coapt
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Prolapse structure tricuspid valve"
              >
                Prolapse
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Flail structure tricuspid valve"
              >
                Flail
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Vegetation structure tricuspid valve"
              >
                Vegetation
              </Item>
              <Submenu label="Prosthetic">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Tricuspid Prosthesis/Repair"
                >
                  Tricuspid prosthesis/repair
                </Item>
                <Submenu label="Regurgitation">
                  <Submenu label="Paravalvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild paravalvular regurgitation prosthetic tricuspid valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate paravalvular regurgitation prosthetic tricuspid valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe paravalvular regurgitation prosthetic tricuspid valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                  <Submenu label="Pathologic Valvular">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Mild pathologic valvular regurgitation prosthetic tricuspid valve"
                    >
                      Mild
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Moderate pathologic valvular regurgitation prosthetic tricuspid valve"
                    >
                      Moderate
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Severe pathologic valvular regurgitation prosthetic tricuspid valve"
                    >
                      Severe
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Elevated Gradient">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Obstruction from Thrombus/Pannus elevated gradient prosthetic mitral valve"
                  >
                    Obstruction from Thrombus/Pannus
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Patients Prosthesis Mismatch elevated gradient tricuspid valve"
                  >
                    Patients Prosthesis Mismatch
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="High Flow elevated gradient prosthetic tricuspid valve"
                  >
                    High Flow
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Structural valve degeneration elevated gradient prosthetic tricuspid valve"
                  >
                    Structural valve degeneration
                  </Item>
                </Submenu>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dehiscence prosethetic tricuspid valve"
                >
                  Dehiscence
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Endocarditis prosethetic tricuspid valve"
                >
                  Endocarditis
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Regurgitation">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Trivial/mild regurgitation tricuspid valve"
              >
                Trivial/mild
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderate regurgitation tricuspid valve"
              >
                Moderate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severe regurgitation tricuspid valve"
              >
                Severe
              </Item>
            </Submenu>
            <Submenu label="Stenosis">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Trivial/mild stenosis tricuspid valve"
              >
                Trivial/mild
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderate stenosis tricuspid valve"
              >
                Moderate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severe stenosis tricuspid valve"
              >
                Severe
              </Item>
            </Submenu>
          </Submenu>
        </Menu>
      );

    case "laAndRa":
      return (
        <Menu id="laAndRa">
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Enlarged Right Atrium"
          >
            Enlarged Right Atrium
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Enlarged Left Atrium"
          >
            Enlarged Left Atrium
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Atrial Myxoma"
          >
            Atrial Myxoma
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Atrial Thrombus"
          >
            Atrial Thrombus
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Atrial Septal Aneurysm"
          >
            Atrial Septal Aneurysm
          </Item>
        </Menu>
      );

    case "vessels":
      return (
        <Menu id="vessels">
          <Submenu label="Diseases of Aorta">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Type A Dissection"
            >
              Type A Dissection
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Type B Dissection"
            >
              Type B Dissection
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Intramural Hematoma"
            >
              Intramural Hematoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Atheromatous plaque"
            >
              Atheromatous plaque
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Coarctation"
            >
              Coarctation
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Aortic Ulcer"
            >
              Aortic Ulcer
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Ascending aortic enlargement or aneurysm"
            >
              Ascending aortic enlargement or aneurysm
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Sinus of Valsalva Aneurysm"
            >
              Sinus of Valsalva Aneurysm
            </Item>
          </Submenu>
          <Submenu label="Congenital Heart Disease">
            <Submenu label="ASD">
              <Item closeOnClick={false} onClick={HandleItemClick} data="PFO">
                PFO
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Primum ASD"
              >
                Primum
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Sinus Venosus ASD"
              >
                Sinus Venosus
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Secundum ASD"
              >
                Secundum
              </Item>
            </Submenu>
            <Submenu label="VSD">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Supracristal VSD"
              >
                Supracristal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Muscular VSD"
              >
                Muscular
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Membranous VSD"
              >
                Membranous
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Dilated coronary sinus"
            >
              Dilated coronary sinus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Anomalous Coronary"
            >
              Anomalous Coronary
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="PDA">
              PDA
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="L-TGA">
              L-TGA
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="D-TGA">
              D-TGA
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="Fontan">
              Fontan
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Tetralogy of Fallot"
            >
              Tetralogy of Fallot
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Epstein's anomaly"
            >
              Epstein's anomaly
            </Item>
          </Submenu>
          <Submenu label="Pericardial And Pleural Diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Tamponade"
            >
              Tamponade
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial Constriction"
            >
              Pericardial Constriction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pleural effusion"
            >
              Pleural effusion
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial Effusion without Tamponade"
            >
              Pericardial Effusion without Tamponade
            </Item>
          </Submenu>
        </Menu>
      );

    case "testConclusion":
      return (
        <Menu id="testConclusion">
          <Item closeOnClick={false} onClick={HandleItemClick} data="Normal">
            Normal
          </Item>
          <Submenu label="Findings">
            <Submenu label="Normal">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LV systolic function, no significant valvular abnormalities"
              >
                No significant valvular abnormalities
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Compared to prior study, there have not been any significant changes"
              >
                Compared to prior study, there have not been any significant
                changes
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Endocarditis prophylaxis is NOT recommended"
              >
                Endocarditis prophylaxis is NOT recommended
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="Recommendations">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV systolic function. No significant valvular abnormalities"
            >
              Normal LV systolic function. No significant valvular abnormalities
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Compared to prior study, there have not been any major changes"
            >
              Compared to prior study, there have not been any major changes
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Compared to prior study, there have been the following changes:"
            >
              Compared to prior study, there have been the following changes:
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Endocarditis prophylaxis is indicated in this setting"
            >
              Endocarditis prophylaxis is indicated in this setting
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Endocarditis prophylaxis is NOT indicated"
            >
              Endocarditis prophylaxis is NOT indicated
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is no obvious source of cardiac emboli"
            >
              There is no obvious source of cardiac emboli
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider repeat Echocardiogram in 6, 12, months"
            >
              Consider repeat Echocardiogram in 6, 12, months
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is no evidence of valvular vegetations or endocarditis"
            >
              There is no evidence of valvular vegetations or endocarditis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings in keeping with prior myocardial infarction"
            >
              Findings in keeping with prior myocardial infarction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings in keeping with hypertrophic cardiomyopathy"
            >
              Findings in keeping with hypertrophic cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is increased speckled appearance of myocardium. Consider infiltrative disorders such as cardiac amyloidosis"
            >
              There is increased speckled appearance of myocardium...
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings suggestive of dilated cardiomyopathy"
            >
              Findings suggestive of dilated cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider repeat Echocardiogram with IV contrast"
            >
              Consider repeat Echocardiogram with IV contrast
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="14. Clinical correlation required"
            >
              Clinical correlation required
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hepatic cysts. Consider correlate with abdominal ultrasound"
            >
              Hepatic cysts. Consider correlate with abdominal ultrasound
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider transesophageal echocardiogram for further evaluation if clinically indicated"
            >
              Consider transesophageal echocardiogram for further evaluation if
              clinically indicated
            </Item>
          </Submenu>
        </Menu>
      );

    case "pathology":
      return (
        <Menu id="pathology">
          <Submenu label="Ventricular Diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Segmental left ventricular dysfunction and LVEF calculation"
            >
              Segmental left ventricular dysfunction and LVEF calculation
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Global left ventricular dysfunction"
            >
              Global left ventricular dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Infiltrative diseases"
            >
              Infiltrative diseases
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hypertrophic cardiomyopathy"
            >
              Hypertrophic cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Global and segmental right ventricular dysfunction"
            >
              Global and segmental right ventricular dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Positive stress echocardiography (exercise and dobutamine) for ischemia"
            >
              Positive stress echocardiography for ischemia
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Distinct patterns of regional LV strain"
            >
              Distinct patterns of regional LV strain
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Left ventricular diastolic dysfunction"
            >
              Left ventricular diastolic dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal septal motion/cardiac dys-synchrony"
            >
              Abnormal septal motion/cardiac dys-synchrony
            </Item>
          </Submenu>
          <Submenu label="Native valvular heart diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with normal EF and normal stroke volume"
            >
              Moderate or worse AS with normal EF and normal stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with normal EF and low stroke volume"
            >
              Moderate or worse AS with normal EF and low stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with low EF and low stroke volume"
            >
              Moderate or worse AS with low EF and low stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with discrepant Doppler measures"
            >
              Moderate or worse AS with discrepant Doppler measures
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Positive dobutamine stress echo for severe AS"
            >
              Positive dobutamine stress echo for severe AS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AI"
            >
              Moderate or worse AI
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="All grades of mitral stenosis"
            >
              All grades of mitral stenosis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="MS score appropriately applied to rheumatic stenosis"
            >
              MS score appropriately applied to rheumatic stenosis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Primary MR due to MV prolapse or other primary MV abnormality"
            >
              Primary MR due to MV prolapse or other primary MV abnormality
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Functional due to ventricular dysfunction, functional atrial MR, ischemic MR with tethered posterior leaflet"
            >
              Functional due to ventricular dysfunction, functional atrial MR,
              ischemic MR
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse PS"
            >
              Moderate or worse PS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse PR"
            >
              Moderate or worse PR
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse TS"
            >
              Moderate or worse TS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse TR (primary or secondary)"
            >
              Moderate or worse TR (primary or secondary)
            </Item>
          </Submenu>
          <Submenu label="Pericardial diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial effusion with associated pertinent
              hemodynamics"
            >
              Pericardial effusion with associated pertinent hemodynamics
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial tamponade (pre and post pericardiocentesis assessment)"
            >
              Pericardial tamponade (pre and post pericardiocentesis assessment)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Constrictive pericarditis"
            >
              Constrictive pericarditis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Effusive constrictive pericarditis"
            >
              Effusive constrictive pericarditis
            </Item>
          </Submenu>
          <Submenu label="Percutaneous or surgically implanted cardiac devices">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Permanent pacemaker/defibrillator wires (in the RA and RV)"
            >
              Permanent pacemaker/defibrillator wires (in the RA and RV)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Venous catheters (in the IVC and RA)"
            >
              Venous catheters (in the IVC and RA)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="PFO/ASD closure devices in the interatrial septum"
            >
              PFO/ASD closure devices in the interatrial septum
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Left atrial appendage closure device"
            >
              Left atrial appendage closure device
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bioprosthesis Aortic – Normal, Stenotic, Regurgitant"
            >
              Bioprosthesis Aortic – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bioprosthesis Mitral – Normal, Stenotic, Regurgitant"
            >
              Bioprosthesis Mitral – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mechanical Aortic – Normal, Stenotic, Regurgitant"
            >
              Mechanical Aortic – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mechanical Mitral – Normal, Stenotic, Regurgitant"
            >
              Mechanical Mitral – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mitral valve post repair (including annuloplasty ring, MitraClip)"
            >
              Mitral valve post repair (including annuloplasty ring, MitraClip)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal MV repair hemodynamics (increased gradients)"
            >
              Abnormal MV repair hemodynamics (increased gradients)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Stenotic prosthetic valves"
            >
              Stenotic prosthetic valves
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Regurgitant prosthetic valves – valvular"
            >
              Regurgitant prosthetic valves – valvular
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Regurgitant prosthetic valves - paravalvular"
            >
              Regurgitant prosthetic valves - paravalvular
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Infective endocarditis and its complications"
            >
              Infective endocarditis and its complications
            </Item>
          </Submenu>
          <Submenu label="Intracardiac masses, pseudo-masses, and cardiac sources of embolism">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Cardiac myxoma"
            >
              Cardiac myxoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Cardiac lipoma"
            >
              Cardiac lipoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Papillary fibroelastoma"
            >
              Papillary fibroelastoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Lambl’s excrescence"
            >
              Lambl’s excrescence
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LV apical thrombus"
            >
              LV apical thrombus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Intracardiac shunts due to PFO/secundum ASD "
            >
              Intracardiac shunts due to PFO/secundum ASD
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal variants/pseudomasses"
            >
              Normal variants/pseudomasses
            </Item>
          </Submenu>
          <Submenu label="Congenital heart diseases (operated and non-operated)">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Atrial septal defect"
            >
              Atrial septal defect
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Atrio-ventricular septal defect repair"
            >
              Atrio-ventricular septal defect repair
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Dilated coronary sinus"
            >
              Dilated coronary sinus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Persistent left-sided SVC"
            >
              Persistent left-sided SVC
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Ventricular septal defect"
            >
              Ventricular septal defect
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Partial anomalous pulmonary venous connection"
            >
              Partial anomalous pulmonary venous connection
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Patent ductus arteriosus"
            >
              Patent ductus arteriosus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Ebstein’s anomaly"
            >
              Ebstein’s anomaly
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Tetralogy of Fallot"
            >
              Tetralogy of Fallot
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Transposition of the great arteries"
            >
              Transposition of the great arteries
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pulmonary arterial hypertension"
            >
              Pulmonary arterial hypertension
            </Item>
          </Submenu>
          <Submenu label="Diseases of the aorta">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Thoracic aortic disease"
            >
              Thoracic aortic disease
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abdominal aortic disease (aneurysm, atheromatous changes)"
            >
              Abdominal aortic disease (aneurysm, atheromatous changes)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bicuspid aortic valve (with and without aortopathy)"
            >
              Bicuspid aortic valve (with and without aortopathy)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Marfan syndrome with aortopathy"
            >
              Marfan syndrome with aortopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Coarctation of aorta"
            >
              Coarctation of aorta
            </Item>
          </Submenu>
          <Submenu label="Normal cases and artefacts">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Artifacts"
            >
              Artifacts
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to poorly aligned Doppler"
            >
              Incorrect continuous wave measurements due to poorly aligned
              Doppler
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to overgain"
            >
              Incorrect continuous wave measurements due to overgain
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to measuring confounding flow"
            >
              Incorrect continuous wave measurements due to measuring
              confounding flow
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect colour flow assessment"
            >
              Incorrect colour flow assessment
            </Item>
          </Submenu>
        </Menu>
      );

    default:
      console.log(`Sorry, we are out of ${menuid.menuid}.`);
      return null;
  }
}
