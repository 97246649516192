// import { findLocalItems } from "../utils/util";
import Cookies from "js-cookie";
import {
  getCurrentSession,
  getCurrentAuthenticatedUser,
} from "../services/auth.service";

// Global fetch wrapper function
async function fetchWithAuth(endpoint, options = {}) {
  const user = await getCurrentAuthenticatedUser();
  const formapiurl = `${user?.attributes["custom:gateway"]}/formapi/api`;

  const session = await getCurrentSession();
  // if no user logged in
  if (session == null) {
    var strClientAccessToken = Cookies.get("ClientAccessToken");
  } else strClientAccessToken = session.accessToken.jwtToken;

  if (!strClientAccessToken) {
    // throw new Error("Authorization token not set");
    console.log("fetchWithAuth strClientAccessToken", strClientAccessToken);
  }

  // Set up headers with Authorization
  const headers = {
    ...options.headers,
    Accept: "application/json",
    accesstoken: strClientAccessToken,
    Authorization: `Bearer ${strClientAccessToken}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(`${formapiurl}/${endpoint}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response.json();
}

const getOne = async (endpoint, itemId) => {
  return fetchWithAuth(`${endpoint}/${itemId}`, {
    method: "GET",
  });
};

const getAll = async (endpoint) => {
  return fetchWithAuth(`${endpoint}`, {
    method: "GET",
  });
};

const createOne = async (endpoint, bodyData) => {
  return fetchWithAuth(`${endpoint}`, {
    method: "POST",
    body: JSON.stringify(bodyData),
  });
};

const updateOne = async (endpoint, bodyData, itemId) => {
  return fetchWithAuth(`${endpoint}/${itemId}`, {
    method: "PUT",
    body: JSON.stringify(bodyData),
  });
};

const deleteOne = async (endpoint, itemId) => {
  return fetchWithAuth(`${endpoint}/${itemId}`, {
    method: "DELETE",
  });
};

// Series API functions
export async function getOneSeries(SeriesInstanceUID) {
  const data = await getOne("series", SeriesInstanceUID);
  return data;
}

export async function getAllSeries() {
  const data = await getAll("series");
  return data;
}

export async function getAllSeriesSpecificFields(fieldList) {
  return fetchWithAuth(`series?fields=${fieldList}`, {
    method: "GET",
  });
}

export async function getAllSeriesForAudit() {
  const fieldList = "SeriesInstanceUID,PatientName,PatientID,SeriesDate,audit";
  const data = await getAllSeriesSpecificFields(fieldList);
  return data;
}

export async function createSeries(bodyData) {
  const data = await createOne("series", bodyData);
  return data;
}

export async function updateSeries(bodyData, SeriesInstanceUID) {
  const data = await updateOne("series", bodyData, SeriesInstanceUID);
  return data;
}

export async function deleteSeries(SeriesInstanceUID) {
  const data = await deleteOne("series", SeriesInstanceUID);
  return data;
}

export async function sendFaxTx(bodyData, SeriesInstanceUID) {
  console.log("bodyData: ", bodyData);

  return fetchWithAuth(`series/${SeriesInstanceUID}/faxtx`, {
    method: "POST",
    body: bodyData,
  });
}

export async function sendShareEmail(
  email,
  SeriesInstanceUID,
  user,
  clinic,
  patient,
  shareType,
  frontend
) {
  const objShare = {
    email: email,
    SeriesInstanceUID: SeriesInstanceUID,
    user: user,
    clinic: clinic,
    PatientID: patient,
    shareType: shareType,
    frontend: frontend,
  };

  return fetchWithAuth(`series/share`, {
    method: "POST",
    body: JSON.stringify(objShare),
  });
}

// Get previous studies done for the same patient before the current study date
export async function getPreviousStudies(PatientID, SeriesDate) {
  return fetchWithAuth(`series/${PatientID}/previous/${SeriesDate}`, {
    method: "GET",
  });
}

// Clinic contact list API functions
export async function getOneClinic(clinicId) {
  const data = await getOne("clinic", clinicId);
  return data.json();
}

export async function getAllClinics() {
  const data = await getAll("clinic");
  return data;
}

export async function createClinic(bodyData) {
  const data = await createOne("clinic", bodyData);
  return data;
}

export async function updateClinic(bodyData, clinicId) {
  const data = await updateOne("clinic", bodyData, clinicId);
  return data;
}

export async function deleteClinic(clinicId) {
  const data = await deleteOne("clinic", clinicId);
  return data;
}

// export async function getReferringPhysicians() {
//   const referringPhysicians = [];
//   const allClinics = await getAllClinics();

//   allClinics.forEach((clinic) => {
//     clinic.referringPhysicians.forEach((rp) => {
//       const referringPhysician = {
//         ...rp,
//         clinicName: clinic.clinicName,
//         fullName: `${rp.firstName} ${rp.lastName}`,
//       };

//       referringPhysicians.push(referringPhysician);
//     });
//   });

//   return referringPhysicians;
// }

// Report template API functions
export async function getAllReportTemplates() {
  const data = await getAll("template");
  return data;
}

export async function createReportTemplate(bodyData) {
  const data = await createOne("template", bodyData);
  return data;
}

export async function updateReportTemplate(bodyData, templateId) {
  const data = await updateOne("template", bodyData, templateId);
  return data;
}

// Normal value measurements API functions
export async function getAllNormalValueMeasurements() {
  const data = await getAll("normalvalue");
  return data;
}

export async function updateNormalValue(bodyData, normalValueMeasurementId) {
  const data = await updateOne(
    "normalvalue",
    bodyData,
    normalValueMeasurementId
  );
  return data;
}

// Referring physicians API calls
export async function getOneReferringPhysician(referringPhysicianId) {
  const data = await getOne("referringphysician", referringPhysicianId);
  return data.json();
}

export async function getAllReferringPhysicians() {
  const data = await getAll("referringphysician");
  return data;
}

export async function createReferringPhysician(bodyData) {
  const data = await createOne("referringphysician", bodyData);
  return data;
}

export async function updateReferringPhysician(bodyData, referringPhysicianId) {
  const data = await updateOne(
    "referringphysician",
    bodyData,
    referringPhysicianId
  );
  return data;
}

export async function deleteReferringPhysician(referringPhysicianId) {
  const data = await deleteOne("referringphysician", referringPhysicianId);
  return data;
}

// Macro API calls
export async function getAllMacros() {
  const data = await getAll("macro");
  return data;
}

export async function createMacro(bodyData) {
  const data = await createOne("macro", bodyData);
  return data;
}

export async function updateMacro(bodyData, macroId) {
  const data = await updateOne("macro", bodyData, macroId);
  return data;
}

// Fax API calls
export async function getStatusOfFaxesFromLastOneMonth() {
  return fetchWithAuth(`faxes/checkStatusOfFaxes`, {
    method: "GET",
  });
}
