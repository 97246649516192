import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import { Amplify } from "aws-amplify";

import {
  Authenticator,
  withAuthenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  useAuthenticator,
  Button,
  CheckboxField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";

import Topbar from "./components/Topbar";
import PatientBrowser from "./components/PatientBrowser";
import PatientPage from "./components/PatientPage";
import Profile from "./components/Profile";
import Footer from "./components/Footer";
import PDFGenerator from "./components/PDFGenerator";
import AuditReport from "./components/AuditReport";
import FaxStatusPage from "./components/FaxStatusPage";
import ManageReferringPhysicians from "./components/ManageReferringPhysicians";
import ManageReportTemplates from "./components/ManageReportTemplates";
import ManageNormalValues from "./components/ManageNormalValues";
import ManageMacros from "./components/ManageMacros";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./App.css";
import { UserProvider, useUser } from "./UserContext";

const queryClient = new QueryClient();

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [localRedirectSignIn, productionRedirectSignIn] =
//   awsExports.oauth.redirectSignIn?.split(",");

// const [localRedirectSignOut, productionRedirectSignOut] =
//   awsExports.oauth.redirectSignOut?.split(",");

const localRedirectSignIn = "https://localhost:3000/";
const productionRedirectSignIn = process.env.REACT_APP_PRODUCTION;

const localRedirectSignOut = "https://localhost:3000/";
const productionRedirectSignOut = process.env.REACT_APP_PRODUCTION;

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

// if (prodEnvironment === true) {
//   const aws = awsExports;
//   Amplify.configure(aws);
//   console.log("AmplifyEnv prod", aws);
// } // if development, use updatedAwsConfig
// else {
//   const aws = updatedAwsConfig;
//   Amplify.configure(aws);
//   console.log("AmplifyEnv not prod", aws);
// }

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="AR logo"
          src="https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ARLogoIcon.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          <SelectField
            name="custom:role"
            label="Role"
            placeholder="Please select a role"
          >
            <option value="cardiologist">Cardiologist</option>
            <option value="sonographer">Sonographer</option>
            <option value="Learner">Learner</option>
            <option value="Educator">Educator</option>
            <option value="patient">Patient</option>
          </SelectField>
          <TextField
            name="custom:clinicName"
            label="Organization"
            placeholder="Please enter the organization name"
          />
          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            //            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="I agree with the Terms & Conditions"
          />
        </>
      );
    },
  },

  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Email support@futurepacs.com For Confirmation
        </Heading>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const services = {
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions",
      };
    }
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
  },
  signUp: {
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
    },
    confirm_password: {
      label: "Confirm Password:",
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignUp: {
    confirmation_code: {
      placeholder: "Email support@futurepacs.com For Confirmation",
      isRequired: false,
    },
  },
};

function App({ isPassedToWithAuthenticator, signOut, user, ...args }) {
  //  if (!isPassedToWithAuthenticator) {
  //    throw new Error(`isPassedToWithAuthenticator was not provided`);
  //  }

  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS={true}>
          <ColorModeScript initialColorMode="light" />
          <Router>
            <header>
              <Topbar signOut={signOut} user={user} />
            </header>
            <Switch>
              <Route exact path={"/"}>
                <PatientBrowser user={user} {...args} />
              </Route>
              <Route exact path={"/audit"} component={AuditReport} />
              <Route exact path={"/faxStatus"} component={FaxStatusPage} />
              <Route
                exact
                path={"/referringphysicians"}
                component={ManageReferringPhysicians}
              />
              <Route
                exact
                path={"/reporttemplates"}
                component={ManageReportTemplates}
              />
              <Route
                exact
                path={"/normalvalues"}
                component={ManageNormalValues}
              />
              <Route exact path={"/macros"} component={ManageMacros} />
              <Route path={"/report/:slug"}>
                <PDFGenerator user={user} />
              </Route>
              <Route path={"/patients/:slug"} component={PatientPage} />
              <Route path="/profile" component={Profile} />
            </Switch>
            <Footer />
            <ReactQueryDevtools initialIsOpen={false} />
          </Router>
        </ChakraProvider>
      </QueryClientProvider>
    </UserProvider>
  );
}

export default withAuthenticator(App, {
  components,
  services,
  formFields,
  loginMechanisms: ["email"],
  variation: ["default"],
  signUpAttributes: ["custom:role", "custom:clinicName"],
});

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}

//               <Route exact path={"/signin"} component={PatientBrowser} />

//              <Route exact path={"/patients"} component={PatientBrowser} />
