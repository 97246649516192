import { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { Storage } from "aws-amplify";

export default function S3AmplifyFileList(props) {
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    async function fetchObjects() {
      // amplify already maps to 'public' but not 'protected' or 'private'
      let objectKeys = await Storage.list(`${props.SeriesInstanceUID}/`, {
        pageSize: "ALL",
        //          level: 'protected'
      });

      objectKeys = await Promise.all(
        objectKeys.results.map(async (k) => {
          const key = await Storage.get(k.key);
          return key;
        })
      );

      //      console.log("objectKeys", objectKeys);

      setObjects(objectKeys);
    }

    fetchObjects();
  }, [props.numFilesUploaded]);

  // handles unique key with hyphen
  const regex = /(?<=-%20)(.*)(?=\?X-Amz)/i;

  //   const regex = /(?<=public\/.*\/)(.*)(?=\?X-Amz)/i;

  //  const regex = /(?<=amazonaws.com\/.*\/.*\/)(.*)(?=\?)/;

  //  const regex = /(?<=protected\/.*\/)(.*)(?=\/)/i;

  return (
    <TableContainer>
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>Uploaded Report Files (click to view)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {objects.length > 0 ? (
            objects?.map((o) => (
              <Tr>
                <Td>
                  <a key={o} href={o} rel="noreferrer" target="_blank">
                    {decodeURI(regex.exec(o)[0])}
                  </a>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>No files uploaded</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
